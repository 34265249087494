/* eslint-disable import/order */
/* eslint-disable react/destructuring-assignment */
import useStyles from './style';
import { setCookies } from '@helper_cookies';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import BannerSlider from './Banner';
import ContentBoundary from '@commons/ContentBoundary';
import CategoryGrid from './CategoryGrid';
// import FlashSaleProducts from './FlashSaleProducts';
// import FeaturedTab from './FeaturedTab';
// import TopSellerProducts from './TopSeller';
// import HomePromo from './HomePromo';
// import CurrentOffer from './CurrentOffer';
// import PromoPartner from './PromoPartner';
// import ShopByBrand from './ShopByBrand';
// import TipsArticle from './TipsArticle';
// import BankBanner from './BankBanner';
// import BankBannerMobile from './BankBannerMobile';
import MegaFooter from './MegaFooter';

// const BannerSlider = dynamic(() => import('./Banner'), { ssr: true });
const FlashSaleProducts = dynamic(() => import('./FlashSaleProducts'), {
    ssr: false,
});
const TopSellerProducts = dynamic(() => import('./TopSeller'), { ssr: false });
const HomePromo = dynamic(() => import('./HomePromo'), { ssr: false });
const CurrentOffer = dynamic(() => import('./CurrentOffer'), { ssr: false });

const PromoPartner = dynamic(() => import('./PromoPartner'), { ssr: false });
const ShopByBrand = dynamic(() => import('./ShopByBrand'), { ssr: false });
const FeaturedTab = dynamic(() => import('./FeaturedTab'), { ssr: false });
const BankBanner = dynamic(() => import('./BankBanner'), { ssr: false });
const BankBannerMobile = dynamic(() => import('./BankBannerMobile'), {
    ssr: false,
});
const TipsArticle = dynamic(() => import('./TipsArticle'), { ssr: false });
const ModalPromo = dynamic(() => import('./ModalPromo'), { ssr: true });
//
// const CategoryGrid = dynamic(() => import('./CategoryGrid'), { ssr: false });
// const ContentBoundary = dynamic(() => import('@commons/ContentBoundary'), { ssr: false });
// const MegaFooter = dynamic(() => import('./MegaFooter'), { ssr: false });

const Content = (props) => {
    const styles = useStyles();
    const { isLogin, ...other } = props;

    // random range number product to show
    const rangeNumber = [1, 5];
    // eslint-disable-next-line max-len
    setCookies(
        'productPageNumber',
        Math.floor(Math.random() * (Math.floor(rangeNumber[1]) - Math.ceil(rangeNumber[0]) + 1)) + Math.ceil(rangeNumber[0]),
    );

    return (
        <div className={styles.container}>
            <h1 className="hidden-desktop hidden-mobile">{props.storeConfig.default_title}</h1>
            <ContentBoundary className={styles.containerBoxBanner}>
                <BannerSlider useThumbor {...other} />
            </ContentBoundary>

            <ContentBoundary className={styles.containerBox}>
                <CategoryGrid />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <FlashSaleProducts {...other} />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <FeaturedTab {...other} url_key="featured-2205" />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <FeaturedTab {...other} url_key="featured-2206" />
            </ContentBoundary>
            <ContentBoundary className={classNames(styles.containerBox, styles.additionalMargin56)}>
                <TopSellerProducts {...other} />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <FeaturedTab {...other} url_key="ide-inspirasi" />
            </ContentBoundary>
            <ContentBoundary className={classNames(styles.containerBox, styles.additionalMargin85)}>
                <HomePromo {...other} />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <CurrentOffer {...other} />
            </ContentBoundary>
            <ContentBoundary className={classNames(styles.containerBox, styles.additionalMargin85)}>
                <PromoPartner {...other} />
            </ContentBoundary>
            <ContentBoundary className={classNames(styles.containerBox, styles.additionalMargin85)}>
                <ShopByBrand {...other} />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <FeaturedTab {...other} url_key="special-for-you" productsPerPage={12} grid />
            </ContentBoundary>
            <ContentBoundary className={styles.containerBox}>
                <TipsArticle {...other} />
            </ContentBoundary>
            <ContentBoundary className={classNames(styles.containerBox, styles.additionalMargin56)}>
                <BankBanner {...other} />
            </ContentBoundary>
            <ContentBoundary className={classNames('hidden-desktop', styles.containerBox, styles.additionalMargin56)}>
                <BankBannerMobile {...other} />
            </ContentBoundary>
            <ContentBoundary style={{ width: '100%' }}>
                <MegaFooter />
            </ContentBoundary>
            <ModalPromo {...other} />
        </div>
    );
};

export default Content;
